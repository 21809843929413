// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import Headroom from "headroom.js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", function() {
  // Menu
  var openMenu = document.getElementById("openMenu");
  if(openMenu) {
    openMenu.addEventListener("click", function() {
      var menu = document.getElementById('menu');
      menu.classList.add("is__open");
      var menuBg = document.getElementById('menuBg');
      menuBg.classList.add('is__open');
    });
  }
  var closeMenu = document.getElementById("closeMenu");
  if(closeMenu) {
    closeMenu.addEventListener("click", function() {
      var menu = document.getElementById('menu');
      menu.classList.remove("is__open");
      var menuBg = document.getElementById('menuBg');
      menuBg.classList.remove('is__open');
    });
  }

  var closeMenuBg = document.getElementById("menuBg");
  if (closeMenuBg) {
    closeMenuBg.addEventListener("click", function () {
      var menu = document.getElementById('menu');
      menu.classList.remove("is__open");
      var menuBg = document.getElementById('menuBg');
      menuBg.classList.remove('is__open');
    });
  }

  // Headroom.js
  var myElement = document.querySelector("header");
  var headroom  = new Headroom(myElement);
  headroom.init();
});
